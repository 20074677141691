import React from "react";
import { Tag } from "@chakra-ui/react";

const TagItem: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Tag
      variant="subtle"
      size={{
        base: "sm",
        md: "md",
        xl: "md",
      }}
      fontFamily={"Body"}
      p={{
        base: 1.5,
        md: 2,
        xl: 2.5,
      }}
      color="black"
      as="b"
    >
      {text}
    </Tag>
  );
};

export default TagItem;
