import {
  DBBannerData,
  WebSiteContactsData,
} from "../../types/websiteData.types";
import MainApiEntry from "../mainApiEntry";

const WebsiteDataApi = MainApiEntry.injectEndpoints({
  endpoints: (build) => ({
    getBannerData: build.query<DBBannerData, void>({
      query: () => "website-data/banner/",
    }),

    getWebsiteContactsData: build.query<WebSiteContactsData, void>({
      query: () => "website-data/contacts/",
    }),
  }),
  overrideExisting: false,
});

export const { useGetBannerDataQuery, useGetWebsiteContactsDataQuery } =
  WebsiteDataApi;
