import React from "react";
import { HStack, Icon, VStack, Text, Button } from "@chakra-ui/react";
import { MdElectricBolt } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const HomeHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <VStack
      spacing={{
        base: 2,
        md: 5,
        xl: 5,
      }}
      padding={{
        base: 5,
        md: 10,
        xl: 20,
      }}
      w="100%"
      direction="column"
      justify="center"
      align="center"
      textAlign={{ base: "center", md: "center", xl: "center" }}
    >
      <HStack
        bg="white"
        pt={[1, 2, 2]}
        pb={[1, 2, 2]}
        pr={[2, 3, 5]}
        pl={[2, 3, 5]}
        borderRadius={[8, 10, 15]}
      >
        <Icon
          as={MdElectricBolt}
          fontSize={{ base: "0", md: "5xl", xl: "7xl" }}
          borderRadius={5}
          color="#f3c923"
          // bg="#FFF4E5"
          p={{ base: 0, md: 1, xl: 3 }}
          me={{ base: 0, md: 2, xl: 3 }}
        />
        <Text
          fontFamily="Heading"
          as="b"
          fontSize={{ base: "sm", md: "2xl", xl: "5xl" }}
        >
          <Text color="#2172B8" as="span">
            {t("HomeHeader.arcade")}
          </Text>
          {t("HomeHeader.header")}
        </Text>
      </HStack>
      <Text
        fontFamily="SubHeading"
        fontSize={{ base: "sm", md: "2xl", xl: "4xl" }}
      >
        {t("HomeHeader.subheader")}
      </Text>
      <Text
        fontFamily="Body"
        fontSize={{ base: "x-small", md: "xl", xl: "2xl" }}
      >
        {t("HomeHeader.description")}
      </Text>

      <Button
        mt="2vh"
        variant={"outline"}
        colorScheme="blue"
        size={{
          base: "sm",
          md: "lg",
          xl: "lg",
        }}
        fontFamily={"Body"}
        onClick={() => navigate("/courses")}
      >
        {t("HomeHeader.button")}{" "}
      </Button>
    </VStack>
  );
};

export default HomeHeader;
