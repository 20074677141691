import React from "react";
import { Box } from "@chakra-ui/react";
import ContactItem from "./Footer/Components/ContactItem";
import SocialIcons from "./SocialIcons";
import { IoMailOutline } from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";
import { SlLocationPin } from "react-icons/sl";
import { useGetWebsiteContactsDataQuery } from "../store/APIs/websiteDataApis";

const ContactItems = () => {
  const { data } = useGetWebsiteContactsDataQuery();

  if (!data) return null;

  return (
    <Box
      w={{
        base: "100%",
        sm: "100%",
        md: "25%",
        xl: "25%",
      }}
    >
      <Box
        mt={5}
        border="1px solid #F1F1F3"
        w="90%"
        borderRadius={10}
        padding={15}
      >
        <ContactItem
          icon={IoMailOutline}
          text={data.email}
          dir={"column"}
          justify={"center"}
          align={"center"}
          mt={5}
          type="mail"
        />
      </Box>

      <Box
        mt={5}
        border="1px solid #F1F1F3"
        w="90%"
        borderRadius={10}
        padding={15}
      >
        <ContactItem
          icon={FiPhoneCall}
          text={data.phone}
          dir={"column"}
          justify={"center"}
          align={"center"}
          mt={5}
          type="phone"
        />
      </Box>

      <Box
        mt={5}
        border="1px solid #F1F1F3"
        w="90%"
        borderRadius={10}
        padding={15}
      >
        <ContactItem
          icon={SlLocationPin}
          text={data.address}
          dir={"column"}
          justify={"center"}
          align={"center"}
          mt={5}
          type="location"
        />
      </Box>

      <Box
        mt={5}
        border="1px solid #F1F1F3"
        w="90%"
        borderRadius={10}
        padding={15}
      >
        <SocialIcons align="center" />
      </Box>
    </Box>
  );
};

export default ContactItems;
