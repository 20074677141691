import React, { useEffect, useState } from "react";
import { Box, Text, Flex, Button, Image } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetAllAdsQuery } from "../../../store/APIs/AdsApis";
import { AdData } from "../../../types/ads.types";

const AdsListing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: Ads } = useGetAllAdsQuery();
  const [adWidth, setAddWidth] = useState<string>("100%");

  useEffect(() => {
    if (Ads && Ads.length === 1) {
      setAddWidth("100%");
    } else if (Ads && Ads.length === 2) {
      setAddWidth("40%");
    } else if (Ads && Ads.length === 3) {
      setAddWidth("30%");
    }
  }, [Ads]);

  if (!Ads || Ads.length === 0) return <></>;

  return (
    <Box
      w={{ base: "90%", sm: "", md: "80%", xl: "90%" }}
      p={{
        base: 1,
        sm: 0,
        md: 5,
        xl: 5,
      }}
    >
      <Flex dir="row" justify="space-between" align="center">
        <Box>
          <Text
            as="b"
            fontSize={{
              base: "lg",
              md: "3xl",
              xl: "4xl",
            }}
            fontFamily={"SubHeading"}
          >
            {/* TODO: Check this Title With Amr */}
            Offers & Ads
          </Text>
        </Box>

        <Button
          size={{
            base: "small",
            md: "md",
            xl: "lg",
          }}
          variant="ghost"
          colorScheme="blue"
          fontSize={{
            base: "x-small",
            md: "md",
            xl: "xl",
          }}
          onClick={() => navigate("/courses")}
          fontFamily={"Light"}
        >
          {t("viewCourses")}
        </Button>
      </Flex>
      <Flex
        mt={5}
        mb={5}
        justify="center"
        align="center"
        flexWrap="wrap"
        rowGap={2}
      >
        {Ads.map((ad: AdData) => (
          <Image
            key={ad._id}
            borderRadius={15}
            src={ad.image}
            alt={ad._id}
            m={5}
            objectFit="cover"
            h={{
              base: "auto",
              sm: "auto",
              md: "auto",
              xl: "80vh",
            }}
            width={adWidth}
            _hover={{
              cursor: "pointer",
              width: "90%",
            }}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default AdsListing;
