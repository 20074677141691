import React from "react";
import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ContactForm from "./Form/ContactForm";
import ContactItems from "../../component/ContactItems";
const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <Flex
      w="100%"
      minH={"75vh"}
      direction="column"
      justify="center"
      rowGap={{
        base: 2,
        sm: 2,
        md: 5,
        xl: 10,
      }}
      mb={5}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Box w="80%">
          <Flex
            w="100%"
            direction={{
              base: "column",
              sm: "column",
              md: "column",
              xl: "row",
            }}
            align={{
              base: "center",
              sm: "center",
              md: "center",
              xl: "center",
            }}
            justifyContent={{
              base: "center",
              sm: "center",
              md: "center",
              xl: "space-around",
            }}
          >
            <Box>
              <Text fontFamily="Heading" fontSize="4xl">
                {t("contactUs.title")}
              </Text>
            </Box>

            <Text
              fontFamily="Body"
              width={{
                base: "100%",
                sm: "100%",
                md: "100%",
                xl: "40%",
              }}
              mt={5}
              color="#59595A"
              fontSize="lg"
            >
              {t("contactUs.description")}
            </Text>
          </Flex>
        </Box>
        <Divider w="90%" m={10} />

        <Flex
          borderRadius={5}
          bg="white"
          w="80%"
          p={{
            base: 5,
            sm: 5,
            md: 10,
            xl: 10,
          }}
          direction={{
            base: "column",
            sm: "column",
            md: "row",
            xl: "row",
          }}
          justify={{
            base: "center",
            sm: "center",
            md: "center",
            xl: "space-between",
          }}
        >
          <ContactForm />
          <ContactItems />
        </Flex>
      </Box>
    </Flex>
  );
};

export default ContactUs;
