import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "../constants/ApiConfig";

export const MainApiEntry = createApi({
    reducerPath: "MainApiEntry",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders(headers, api) {
            const token = localStorage.getItem("token");
            if (token) {
                headers.set("token", JSON.parse(token));
            }
            return headers;
        },
        responseHandler: async (response) => {
            const res = await response.json();
            if (response.ok) {
                return res.data;
            }

            if (response.status === 401 || response.status === 403) {
                await localStorage.removeItem("token");
                await localStorage.removeItem("user");
                window.location.href = "/"

            }
            const error = res.error || res.message;
            console.log("Errors", error)
        },
    }),
    tagTypes: ["Courses"],

    endpoints: (builder) => ({}),
});

export default MainApiEntry