import { CourseData } from "../../types/courses.types";
import MainApiEntry from "../mainApiEntry";

const CoursesApi = MainApiEntry.injectEndpoints({
    endpoints: (build) => ({
        getAllCourses: build.query<CourseData[], void>({
            query: () => "course/",
        }),

        getCourseById: build.query<CourseData, string>({
            query: (id) => `course/${id}`,
        }),
    }),
    overrideExisting: false,
})

export const { useGetAllCoursesQuery, useGetCourseByIdQuery } = CoursesApi
