import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Stack,
  Text,
  Image,
  HStack,
} from "@chakra-ui/react";
import TagItem from "./TagItem";
import { useTranslation } from "react-i18next";
import { CourseCardProps } from "../types/courses.types";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstChar } from "../util/stringUtils";

const CourseCard: React.FC<CourseCardProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = localStorage.getItem("Lang");
  const [durationType, setDurationType] = useState<string>(props.duration.type);

  useEffect(() => {
    if (lang === "ar") {
      if (props.duration.type === "week") {
        setDurationType("أسبوع");
      } else if (props.duration.type === "hour") {
        setDurationType("ساعة");
      }
    }
  }, [lang]);

  const handleNavigation = () => navigate(`/courses/${props.id}`);

  return (
    <Card
      bg="white"
      w={{
        base: "90%",
        md: "100%",
        lg: "90%",
        xl: "30%",
      }}
      m={{
        base: 2,
        sm: 2,
        md: 3,
        xl: 2,
      }}
      h={{
        base: "auto",
        sm: "auto",
        md: "auto",
        xl: "80vh",
      }}
      p={{
        base: 1,
        sm: 3,
        md: 6,
        xl: 6,
      }}
    >
      <CardBody>
        <Image
          src={
            props.image
              ? props.image
              : "https://placehold.co/600x400/lightblue/white?text=No\n Image"
          }
          alt={`${props.name_en}-Image`}
          borderRadius="lg"
          w={"100%"}
          h={"42vh"}
          onClick={handleNavigation}
        />
        <Stack mt="6" spacing="3">
          <HStack spacing={4}>
            <TagItem
              text={`${props.duration.value} ${capitalizeFirstChar(
                durationType
              )}`}
            />
            <TagItem
              text={`${
                props.category &&
                (lang === "en"
                  ? capitalizeFirstChar(props.category.name_en)
                  : props.category.name_ar)
              }`}
            />
          </HStack>
          <Heading fontFamily="Heading" size="md">
            {lang === "en" ? props.name_en : props.name_ar}
          </Heading>
          <Text
            fontFamily="Body"
            fontSize={{ base: "x-small", md: "md", xl: "lg" }}
            noOfLines={4}
          >
            {lang === "en" ? props.description_en : props.description_ar}
          </Text>
        </Stack>
      </CardBody>

      <CardFooter w="100%">
        <Button
          variant="outline"
          colorScheme="blue"
          fontFamily={"Body"}
          w="100%"
          size={{
            base: "sm",
            md: "md",
            xl: "lg",
          }}
          fontSize={{
            base: "x-small",
            md: "md",
            xl: "lg",
          }}
          onClick={handleNavigation}
        >
          {t("Course.view")}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default CourseCard;
