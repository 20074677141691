import React, { useEffect, useState } from "react";
import { useGetAllCoursesQuery } from "../../store/APIs/CoursesApi";
import {
  Box,
  Button,
  Flex,
  HStack,
  Select,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ResponsivePagination from "react-responsive-pagination";
import PaginatedListing from "./components/PaginatedListing";
import { CourseData } from "../../types/courses.types";
import { SmallCloseIcon } from "@chakra-ui/icons";
import { MdArrowDropDown } from "react-icons/md";

const COURSES_PER_PAGE = 9;

const Courses = () => {
  const { t } = useTranslation();
  const [filteredCourses, setFilteredCourses] = useState<CourseData[]>([]);
  const [filterSelectedOption, setFilterSelectedOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(2);
  const lang = localStorage.getItem("Lang");

  const { data: courses, isLoading } = useGetAllCoursesQuery();

  useEffect(() => {
    courses && setTotalPages(Math.ceil(courses.length / 9));
    courses && setFilteredCourses(courses);
  }, [courses]);

  if (isLoading) return <Spinner textAlign="center" />;
  if (!courses) return <Text>No Courses</Text>;

  const handleFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterSelectedOption(e.target.value);

    const filtered = courses.filter(
      (course: any) => course.category.name_en === e.target.value
    );

    setFilteredCourses(filtered);
  };

  const handleClearFilter = () => {
    setFilterSelectedOption("");
    setFilteredCourses(courses);
  };

  return (
    <Flex
      mt={{
        base: 5,
        sm: 5,
        md: 0,
        xl: 0,
      }}
      w="100%"
      minH={"100vh"}
      direction="column"
      align="flex-start"
      rowGap={{
        base: 2,
        sm: 2,
        md: 5,
        xl: 10,
      }}
      p={{
        base: 0,
        sm: 0,
        md: 10,
        xl: 50,
      }}
    >
      <Flex
        paddingStart={{
          base: 5,
          sm: "10vh",
          md: "10vh",
          xl: "10vh",
        }}
        dir="row"
        align="center"
        w={"90%"}
        justify="space-between"
      >
        <Text
          as="b"
          fontSize={{
            base: "lg",
            md: "3xl",
            xl: "4xl",
          }}
          fontFamily={"SubHeading"}
        >
          {t("ourCourses")}
        </Text>
        <HStack
          boxSize={{
            base: "45%",
            sm: "40%",
            md: "40%",
            xl: "auto",
          }}
        >
          <Select
            variant="filled"
            onChange={handleFilter}
            fontFamily="Body"
            placeholder={t("Filtering.filterBy")}
            icon={<MdArrowDropDown />}
            value={filterSelectedOption}
            style={lang === "ar" ? { paddingRight: "35px" } : {}}
            size={{
              base: "sm",
              sm: "sm",
              md: "md",
              xl: "lg",
            }}
          >
            <option value="general">{t("Filtering.General")}</option>
            <option value="mechanical">{t("Filtering.Mechanical")}</option>
            <option value="electrical">{t("Filtering.Electrical")}</option>
            <option value="civil">{t("Filtering.Civil")}</option>
            <option value="architecture">{t("Filtering.Architecture")}</option>
          </Select>

          {filterSelectedOption !== "" && (
            <Button
              leftIcon={<SmallCloseIcon boxSize={4} />}
              fontFamily="Light"
              fontSize={{
                base: "xs",
                md: "md",
                xl: "lg",
              }}
              variant="solid"
              onClick={handleClearFilter}
              size={{
                base: "xs",
                sm: "sm",
                md: "md",
                xl: "lg",
              }}
            >
              {t("Filtering.clear")}
            </Button>
          )}
        </HStack>
      </Flex>

      <Flex width="100%" flexWrap="wrap" justify="center" align="center">
        {isLoading ? (
          <Spinner textAlign="center" />
        ) : (
          <Box mb={3}>
            <PaginatedListing
              courses={filteredCourses}
              currentPage={currentPage}
              limitPerPage={COURSES_PER_PAGE}
            />

            {filteredCourses.length > 0 && (
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={setCurrentPage}
              />
            )}
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default Courses;
