import React from "react";
import { Flex, Icon, Text } from "@chakra-ui/react";
import { IconType } from "react-icons";

interface Props {
  icon: IconType;
  text: string;
  dir: "row" | "column";
  justify: "center" | "space-evenly";
  align: "flex-start" | "center";
  mt?: number;
  type: "phone" | "mail" | "location";
}

const ContactItem: React.FC<Props> = ({
  icon,
  text,
  dir,
  justify,
  align,
  mt,
  type,
}) => {
  const actions = {
    phone: `tel:+20${text}`,
    mail: `mailto: ${text}?subject=New Message To Arcade&body=Dear Arcade,`,
    location: "",
  };

  return (
    <Flex
      direction={dir}
      justify={justify}
      align={align}
      padding={1}
      mb={{ base: 0, md: 0 }}
    >
      <Icon color="#2172B8" as={icon} me={2} />
      <a href={actions[type]} target="_blank" rel="noreferrer">
        <Text fontFamily="Body" mt={mt || 0}>
          {text}
        </Text>
      </a>
    </Flex>
  );
};

export default ContactItem;
