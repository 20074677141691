import React, { useEffect, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import CourseCard from "../../../component/CourseCard";
import { CourseData, ICategory } from "../../../types/courses.types";

const PaginatedListing: React.FC<{
  courses: CourseData[];
  currentPage: number;
  limitPerPage: number;
}> = ({ courses, currentPage, limitPerPage }) => {
  const [curCourses, setCurCourses] = useState<any>([]);

  useEffect(() => {
    const offset = (currentPage - 1) * limitPerPage;
    const getList = (currentPage: number, limitPerPage: number) => {
      setCurCourses(courses.slice(offset, offset + limitPerPage));
    };

    getList(currentPage, limitPerPage);
  }, [currentPage, courses, limitPerPage]);

  return (
    <Flex justify="center" align="center" flexWrap="wrap">
      {curCourses.length === 0 && (
        <Text m={2} fontFamily="Heading">
          No Courses Found
        </Text>
      )}
      {curCourses.length > 0 &&
        curCourses.map((course: CourseData) => (
          <CourseCard
            key={course._id}
            name_en={course.name_en}
            name_ar={course.name_ar}
            description_en={course.description_en}
            description_ar={course.description_ar}
            duration={JSON.parse(course.period)}
            id={course._id as string}
            image={course.media as string}
            category={course.category as ICategory}
          />
        ))}
    </Flex>
  );
};

export default PaginatedListing;
