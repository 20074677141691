import React from "react";
import { Flex } from "@chakra-ui/react";
import HomeHeader from "./Components/HomeHeader";
import VideoItem from "./Components/VideoItem";
import CourseListing from "./Components/CourseListing";
import AdsListing from "./Components/AdsListing";
// import FeedbackListing from "./Components/FeedbackListing";

const Home = () => {
  return (
    <Flex
      w="100%"
      direction="column"
      align="center"
      rowGap={{
        base: 5,
        sm: 2,
        md: 5,
        xl: 14,
      }}
    >
      <HomeHeader />
      <VideoItem />
      <CourseListing />
      <AdsListing />
      {/* TODO: Commented till we have the feedback DB and admin panel part */}
      {/* <FeedbackListing /> */}
    </Flex>
  );
};

export default Home;
