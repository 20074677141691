import React from "react";
import { RiFacebookFill } from "react-icons/ri";
import { RiInstagramLine } from "react-icons/ri";
import { RiYoutubeLine } from "react-icons/ri";
import { RiWhatsappLine } from "react-icons/ri";
import SocialItem from "./Footer/Components/SocialItem";
import { Flex, Text, HStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useGetWebsiteContactsDataQuery } from "../store/APIs/websiteDataApis";

const SocialIcons: React.FC<{ align: "center" | "flex-start" }> = ({
  align,
}) => {
  const { t } = useTranslation();
  const { data } = useGetWebsiteContactsDataQuery();

  if (!data) return null;

  return (
    <Flex
      direction="column"
      align={{
        base: "center",
        md: "flex-start",
        xl: align,
      }}
      m={{
        base: 5,
        md: 0,
        xl: 0,
      }}
    >
      <Text fontFamily="Heading" as="b">
        {t("social")}
      </Text>
      <HStack mt={3} spacing={3}>
        <SocialItem icon={RiFacebookFill} url={data.facebook} />
        <SocialItem icon={RiInstagramLine} url={data.instagram} />
        <SocialItem icon={RiYoutubeLine} url={data.youtube} />

        <SocialItem
          icon={RiWhatsappLine}
          url={`https://api.whatsapp.com/send?phone=2${data.whatsapp}`}
        />
      </HStack>
    </Flex>
  );
};

export default SocialIcons;
