import { extendTheme, theme as base } from "@chakra-ui/react";

const storedLang = localStorage.getItem('Lang') || "en";

const fonts = {
    Heading: `${storedLang === "en" ? 'EnglishBold' : 'ArabicBold'} , ${base.fonts?.heading}, sans-serif`,
    SubHeading: `${storedLang === "en" ? 'EnglishMedium' : 'ArabicMedium'}, ${base.fonts?.heading}, sans-serif`,
    Body: `${storedLang === "en" ? 'EnglishRegular' : 'ArabicRegular'}, ${base.fonts?.body}, sans-serif`,
    Light: `${storedLang === "en" ? 'EnglishLight' : 'ArabicLight'}, ${base.fonts?.body}, sans-serif`,
};

const theme = extendTheme({ fonts });

export default theme;