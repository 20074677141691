import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const storedLang = localStorage.getItem('Lang');

i18n.use(initReactI18next).init({
    fallbackLng: "en",
    lng: storedLang || "en",
    resources: {
        en: {
            translations: require("../localization/Languages/en.json")
        },
        ar: {
            translations: require("../localization/Languages/ar.json")
        },
    },
    ns: ["translations"],
    defaultNS: "translations"
});

i18n.languages = ["en", "ar"];

export default i18n;
