import React from "react";
import { Flex, Box, Text, Button } from "@chakra-ui/react";
import CourseCard from "../../../component/CourseCard";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetAllCoursesQuery } from "../../../store/APIs/CoursesApi";
import { CourseData, ICategory } from "../../../types/courses.types";

const CourseListing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: courses } = useGetAllCoursesQuery();

  return (
    <Box
      w={{ base: "90%", sm: "", md: "80%", xl: "90%" }}
      p={{
        base: 1,
        sm: 0,
        md: 5,
        xl: 5,
      }}
    >
      <Flex dir="row" justify="space-between" align="center">
        <Box>
          <Text
            as="b"
            fontSize={{
              base: "lg",
              md: "3xl",
              xl: "4xl",
            }}
            fontFamily={"SubHeading"}
          >
            {t("ourCourses")}
          </Text>

          <Text
            fontSize={{
              base: "xx-small",
              md: "md",
              xl: "xl",
            }}
            fontFamily={"Body"}
          >
            {t("coursesDescription")}
          </Text>
        </Box>

        <Button
          size={{
            base: "small",
            md: "md",
            xl: "lg",
          }}
          variant="ghost"
          colorScheme="blue"
          fontSize={{
            base: "x-small",
            md: "md",
            xl: "xl",
          }}
          onClick={() => navigate("/courses")}
          fontFamily={"Light"}
        >
          {t("viewAll")}
        </Button>
      </Flex>
      <Flex
        mt={5}
        mb={5}
        justify="center"
        align="center"
        flexWrap="wrap"
        rowGap={2}
      >
        {courses?.slice(0, 3).map((course: CourseData) => (
          <CourseCard
            key={course._id}
            id={course._id as string}
            name_en={course.name_en}
            name_ar={course.name_ar}
            description_en={course.description_en}
            description_ar={course.description_ar}
            duration={JSON.parse(course.period)}
            image={course.media as string}
            category={course.category as ICategory}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default CourseListing;
