import { Icon, IconButton } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";

interface Props {
  icon: IconType;
  url: string;
}

const SocialItem: React.FC<Props> = ({ icon, url }) => {
  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <IconButton
      colorScheme="blue"
      variant="outline"
      aria-label="Search database"
      icon={<Icon as={icon} fontSize={18} />}
      justifyContent="center"
      alignItems="center"
      onClick={() => openInNewTab(url)}
    />
  );
};

export default SocialItem;
