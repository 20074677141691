import React from "react";
import {
  Box,
  Link,
  Image,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Button,
  Flex,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import Logo from "../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdLanguage } from "react-icons/md";

const MenuItems = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon />}
        variant="outline"
      />
      <MenuList>
        <MenuItem onClick={() => navigate("/")}>{t("home")}</MenuItem>
        <MenuItem onClick={() => navigate("/courses")}>{t("courses")}</MenuItem>
        <MenuItem onClick={() => navigate("/contact")}>
          {t("contactus")}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const Header = () => {
  const { t, i18n } = useTranslation();
  const storedLang = localStorage.getItem("Lang");
  const [lang, setLang] = React.useState(storedLang);

  const handleChangeLang = (): void => {
    setLang(lang === "en" ? "ar" : "en");
    localStorage.setItem("Lang", lang === "en" ? "ar" : "en");
    window.location.reload();
    i18n.changeLanguage(lang === "en" ? "ar" : "en");
  };

  return (
    <Box fontFamily="Heading">
      <Flex
        dir="row"
        p={{ base: 1, sm: 2, md: 4, xl: 4 }}
        justify="space-around"
        align="center"
      >
        <Box display={{ base: "block", md: "none" }}>
          <MenuItems />
        </Box>

        <Box
          w={{ base: "20%", sm: "15%", md: "35", xl: "100px" }}
          ms={{ base: "30px", sm: "10px", md: "0px", xl: "0px" }}
        >
          <Link href="/">
            <Image
              src={Logo}
              alt="Arcade Logo"
              style={{ cursor: "pointer", display: "block" }}
              w="100%"
            />
          </Link>
        </Box>

        <HStack
          spacing={{ base: 0, md: "24px" }}
          display={{ base: "none", md: "flex" }}
        >
          <Link p={2} href="/">
            {t("home")}
          </Link>
          <Link p={2} href="/courses">
            {t("courses")}
          </Link>
          <Link p={2} href="/contact">
            {t("contactus")}
          </Link>
        </HStack>

        <Button
          leftIcon={<MdLanguage fontSize={14} />}
          colorScheme="blue"
          variant="outline"
          fontSize={13}
          fontFamily="Heading"
          onClick={handleChangeLang}
          size={{ base: "xs", md: "sm", xl: "sm" }}
          w={{ base: "110px", md: "100px", xl: "100px" }}
        >
          {lang === "en" ? "EN" : "عربي"}
        </Button>
      </Flex>
    </Box>
  );
};

export default Header;
