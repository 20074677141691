import React from "react";
import { Flex, HStack, Icon, Text } from "@chakra-ui/react";
import { FaArrowRight } from "react-icons/fa6";
import { useGetBannerDataQuery } from "../store/APIs/websiteDataApis";

const AnnouncementBanner = () => {
  const { data: bannerData } = useGetBannerDataQuery();

  if (!bannerData) return null;
  if (bannerData && !bannerData.showInHome) return null;

  return (
    <Flex
      justify={"center"}
      align={"center"}
      w={{ base: "95%", md: "90%", xl: "90%" }}
      bg="#2172B8"
      p={2}
      borderRadius={5}
    >
      <HStack spacing={2}>
        <Text
          fontSize={{
            base: "sm",
            md: "md",
            xl: "xl",
          }}
          color="white"
          fontFamily="Heading"
        >
          {bannerData.header}
        </Text>
        <Text>🌟</Text>
        <Text
          fontSize={{
            base: "sm",
            md: "md",
            xl: "xl",
          }}
          color="white"
          fontFamily="Heading"
        >
          {bannerData.subheader}
        </Text>

        <Icon
          as={FaArrowRight}
          fontSize={{ base: "sm", md: "sm", xl: "lg" }}
          borderRadius={5}
          color="white"
          _hover={{
            cursor: "pointer",
          }}
          onClick={() =>
            window.open(bannerData.actionLink, "_blank", "noopener")
          }
        />
      </HStack>
    </Flex>
  );
};

export default AnnouncementBanner;
