import { AdData } from "../../types/ads.types";
import MainApiEntry from "../mainApiEntry";

const AdsApi = MainApiEntry.injectEndpoints({
  endpoints: (build) => ({
    getAllAds: build.query<AdData[], void>({
      query: () => "ads/active",
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllAdsQuery } = AdsApi;
