import React, { useEffect } from "react";
import { ChakraProvider, Flex, Grid, GridItem } from "@chakra-ui/react";
import Header from "./component/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import Home from "./pages/Home/Home";
import Courses from "./pages/Courses/Courses";
import ContactUs from "./pages/ContactUs/ContactUs";
import Footer from "./component/Footer/Footer";
import "./localization/i18Config";
import { useTranslation } from "react-i18next";
import theme from "./constants/theme";
import "swiper/css";
import "react-responsive-pagination/themes/classic.css";
import SingleCourse from "./pages/SingleCourse/SingleCourse";
import AnnouncementBanner from "./component/AnnouncementBanner";

function App() {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();

  useEffect(() => {
    const storedLang = localStorage.getItem("Lang");
    if (!storedLang) {
      localStorage.setItem("Lang", "en");
      i18n.changeLanguage("en");
    }
  });

  return (
    <Router>
      <ChakraProvider theme={theme}>
        <Grid
          templateAreas={`"header header" "main main" "footer footer"`}
          gridTemplateRows={"50px 1fr 30px"}
          gridTemplateColumns={"150px 1fr"}
          h="100%"
          display="flex"
          flexDirection="column"
          bg="#F7F7F8"
        >
          <Flex justify="center" w="100%" m={3}>
            <AnnouncementBanner />
          </Flex>

          <GridItem area={"header"}>
            <Header />
          </GridItem>

          <GridItem area={"main"} width={"100%"}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/courses/:courseId" element={<SingleCourse />} />
              <Route path="/contact" element={<ContactUs />} />
            </Routes>
          </GridItem>

          <GridItem area={"footer"}>
            <Footer />
          </GridItem>
        </Grid>

        <Analytics />
        <SpeedInsights />
      </ChakraProvider>
    </Router>
  );
}

export default App;
