import React from "react";
import { AspectRatio } from "@chakra-ui/react";

const VideoItem = () => {
  return (
    <AspectRatio
      w={{ base: "90%", md: "80%", xl: "90%" }}
      ratio={{ base: 2, md: 2, xl: 2.5 }}
      p={5}
      borderRadius={10}
    >
      <iframe
        style={{ borderRadius: 10 }}
        src="https://www.youtube.com/embed/zKO5b3DiXSc"
        allowFullScreen={false}
        title="YouTube video player"
      />
    </AspectRatio>
  );
};

export default VideoItem;
