import { combineReducers, configureStore } from '@reduxjs/toolkit';
import MainApiEntry from './mainApiEntry';

const rootReducer = combineReducers({
    [MainApiEntry.reducerPath]: MainApiEntry.reducer,
})

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(MainApiEntry.middleware),
});

export type RootState = ReturnType<typeof rootReducer>
export default store;
