import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ContactUsDataSchema, initialValues } from "./validations";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";

const REACT_APP_GOOGLE_SHEET_URL =
  "https://api.sheetapi.rest/api/v1/sheet/tfYpOk27GmlwkBNVYsOIm";
// "https://sheet.best/api/sheets/47c23bf0-097c-4492-a676-6805e75958df";
const ContactForm = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(ContactUsDataSchema),
  });
  const toast = useToast({
    position: "top-right",
    colorScheme: "blue",
    isClosable: true,
  });

  const formFields = [
    {
      name: "firstName" as "firstName",
      label: t("contactUs.firstName"),
      placeHolder: t("contactUs.firstNamePH"),
      isError: errors.firstName,
      errorMsg: errors.firstName?.message,
      width: "50%",
    },
    {
      name: "lastName" as "lastName",
      label: t("contactUs.lastName"),
      placeHolder: t("contactUs.lastNamePH"),
      isError: errors.lastName,
      width: "50%",
      errorMsg: errors.lastName?.message,
    },
    {
      name: "email" as "email",
      label: t("contactUs.email"),
      placeHolder: t("contactUs.emailPH"),
      isError: errors.email,
      errorMsg: errors.email?.message,
      width: "50%",
    },
    {
      name: "phone" as "phone",
      label: t("contactUs.phone"),
      placeHolder: t("contactUs.phonePH"),
      isError: errors.phone,
      errorMsg: errors.phone?.message,
      width: "50%",
    },
    {
      name: "subject" as "subject",
      label: t("contactUs.subject"),
      placeHolder: t("contactUs.subjectPH"),
      isError: errors.subject,
      errorMsg: errors.subject?.message,
      width: "100%",
    },
    {
      name: "message" as "message",
      label: t("contactUs.message"),
      placeHolder: t("contactUs.messagePH"),
      isError: errors.message,
      errorMsg: errors.message?.message,
      width: "100%",
    },
  ];

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    try {
      const res = await axios({
        method: "POST",
        url: REACT_APP_GOOGLE_SHEET_URL,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          Id: uuidv4(),
          FirstName: data.firstName,
          LastName: data.lastName,
          Email: data.email,
          Phone: data.phone,
          Subject: data.subject,
          Message: data.message,
          Status: "New",
          CreatedAt: new Date(),
        },
      });

      setIsLoading(false);

      if (res.status === 200) {
        toast({
          title: "Message sent",
          description: "We will get back to you soon",
          status: "success",
          duration: 3000,
        });
        reset();
      } else {
        toast({
          title: "Message not sent",
          description: "Something went wrong, Please try again later",
          status: "error",
          duration: 3000,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Message not sent",
        description: "Something went wrong, Please try again later",
        status: "error",
        duration: 3000,
      });
    }
  };

  return (
    <Box
      w={{
        base: "100%",
        sm: "100%",
        md: "80%",
        xl: "70%",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex w="100%" dir="column" align="center" justify="center" wrap="wrap">
          {formFields.map((field) => (
            <FormControl
              p={2}
              w={{
                base: "100%",
                sm: "100%",
                md: field.width,
                xl: field.width,
              }}
              key={field.name}
              isInvalid={field.isError ? true : false}
            >
              <FormLabel>{field.label}</FormLabel>
              {field.name === "message" ? (
                <Textarea
                  size="md"
                  placeholder={field.placeHolder}
                  {...register(field.name)}
                />
              ) : (
                <Input
                  size="md"
                  type="text"
                  placeholder={field.placeHolder}
                  {...register(field.name)}
                />
              )}

              {field.isError && (
                <FormErrorMessage>{field.errorMsg}</FormErrorMessage>
              )}
            </FormControl>
          ))}

          <Button
            mt={4}
            colorScheme="blue"
            isLoading={isLoading}
            type="submit"
            size={{
              base: "sm",
              sm: "sm",
              md: "md",
              xl: "md",
            }}
            variant="outline"
          >
            {t("contactUs.send")}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default ContactForm;

// https://script.google.com/macros/s/AKfycbz5AgXyf0X-6hbLLmv53Ug0QXmRRP5_Z4QHpBLL5K6FTvRMHuktT91KTxY95od3cWotZw/exec
