import React, { useEffect, useState } from "react";
import {
  AspectRatio,
  Avatar,
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useGetCourseByIdQuery } from "../../store/APIs/CoursesApi";
import parse from "html-react-parser";
import TagItem from "../../component/TagItem";
import { t } from "i18next";
import { AiOutlineUser } from "react-icons/ai";
// import { getFileType } from "../../util/getFileType";
// import { Swiper, SwiperSlide } from "swiper/react";
// import VideoComponent from "./Feedback/Video";
// import AudioComponent from "./Feedback/Audio";
// import ImageComponent from "./Feedback/Image";
import { capitalizeFirstChar } from "../../util/stringUtils";

const SingleCourse = () => {
  const [courseContent, setCourseContent] = useState<string>("");
  const [type, setType] = useState<string[]>([]);
  const { courseId } = useParams();
  const lang = localStorage.getItem("Lang");
  const { data: courseData, isLoading } = useGetCourseByIdQuery(
    courseId as string
  );

  useEffect(() => {
    if (courseData) {
      if (lang === "ar") {
        if (
          courseData.courseContent_ar &&
          courseData.courseContent_ar !== "<p><br></p>"
        ) {
          setCourseContent(courseData.courseContent_ar);
          setType(courseData.type_ar);
        } else {
          // Use English content if Arabic content is not provided or is "<p><br></p>"
          setCourseContent(courseData.courseContent_en);
          setType(courseData.type_ar);
        }
      } else {
        if (
          courseData.courseContent_en &&
          courseData.courseContent_en !== "<p><br></p>"
        ) {
          setCourseContent(courseData.courseContent_en);
          setType(courseData.type_en);
        } else {
          // Use Arabic content if English content is not provided or is "<p><br></p>"
          setCourseContent(courseData.courseContent_ar);
          setType(courseData.type_en);
        }
      }
    }
  }, [courseData, lang]);

  if (!courseData) return <Text>No Course Was Found</Text>;

  return (
    <Flex
      w="100%"
      minH={"100vh"}
      direction="column"
      align="flex-start"
      rowGap={{
        base: 2,
        sm: 2,
        md: 5,
        xl: 10,
      }}
      mt={5}
      mb={5}
    >
      {isLoading ? (
        <Spinner textAlign="center" />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <VStack w={{ base: "90%", md: "90%", xl: "80%" }} spacing={10}>
            <Box w="90%">
              <Flex
                direction={{
                  base: "column",
                  sm: "column",
                  md: "column",
                  xl: "row",
                }}
                align="center"
                justifyContent="space-around"
              >
                <Box>
                  <Text fontFamily="Heading" fontSize="4xl" width={"98%"}>
                    {lang === "en" ? courseData.name_en : courseData.name_ar}
                  </Text>

                  <HStack pt={5} spacing={4}>
                    <TagItem
                      text={`${JSON.parse(courseData.period).value} ${
                        lang === "en"
                          ? capitalizeFirstChar(
                              JSON.parse(courseData.period).type
                            )
                          : "أسبوع"
                      } `}
                    />

                    <TagItem
                      text={`${
                        courseData.category &&
                        (lang === "en"
                          ? capitalizeFirstChar(courseData.category.name_en)
                          : courseData.category.name_ar)
                      }`}
                    />

                    {type.map((type: string) => (
                      <TagItem key={type} text={capitalizeFirstChar(type)} />
                    ))}
                  </HStack>
                </Box>

                <Text
                  fontFamily="Body"
                  width="40%"
                  color="#59595A"
                  fontSize="sm"
                  w={{
                    base: "100%",
                    sm: "100%",
                    md: "100%",
                    xl: "50%",
                  }}
                  mt={{
                    base: 5,
                    sm: 5,
                    md: 5,
                    xl: 0,
                  }}
                >
                  {lang === "en"
                    ? courseData.description_en
                    : courseData.description_ar}
                </Text>
              </Flex>
            </Box>

            <Divider />

            <AspectRatio
              w={"100%"}
              ratio={{ base: 2, md: 2, xl: 21 / 9 }}
              p={{
                base: 0,
                sm: 0,
                md: 20,
                xl: 5,
              }}
              borderRadius={10}
            >
              <Image
                src={
                  courseData.media
                    ? courseData.media
                    : "https://placehold.co/600x400/lightblue/white?text=No\n Image"
                }
                alt={`${courseData.name_en}-image`}
                borderRadius="lg"
                width="80%"
                fit="cover"
              />
            </AspectRatio>

            <Text fontFamily="Heading" fontSize="4xl">
              {t("courseContent")}
            </Text>
            <Flex width="80%" align="center" justify="flex-start">
              <Text>{parse(courseContent)}</Text>
            </Flex>

            {/* Commented For now to be handled after 1st release */}
            {/* {courseData.courseData && courseData.courseData?.length > 0 && (
              <Box w={"90%"} h={500} border="1px solid red">
                <Text>Course Feedback Data, Handle Video and Audio Cases</Text>
                <Swiper
                  spaceBetween={50}
                  initialSlide={1}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper: any) => console.log(swiper)}
                >
                  {courseData.courseData?.map((item: string) => {
                    const type = getFileType(item);
                    if (type === "Video")
                      return (
                        <SwiperSlide>
                          <VideoComponent url={item} />
                        </SwiperSlide>
                      );
                    // else if (type === "Audio")
                    //   return (
                    //     <SwiperSlide>
                    //       <AudioComponent url={item} />
                    //     </SwiperSlide>
                    //   );
                    else
                      return (
                        <SwiperSlide>
                          <ImageComponent url={item} />
                        </SwiperSlide>
                      );
                  })}
                </Swiper>
              </Box>
            )} */}

            <Flex w="100%" dir="row" align="center">
              <Divider borderColor="#2172B8" orientation="horizontal" />
              <Box textAlign="center" w="40%">
                <Avatar
                  color="white"
                  fontFamily="Heading"
                  icon={<AiOutlineUser fontSize="3.5rem" />}
                  size="xl"
                  bg="#2172B8"
                />
                <Text fontFamily="Heading" mt={4} fontSize="xl">
                  {lang === "en"
                    ? courseData.lecturerName_en
                    : courseData.lecturerName_ar}
                </Text>
              </Box>
              <Divider borderColor="#2172B8" orientation="horizontal" />
            </Flex>
          </VStack>
        </Box>
      )}
    </Flex>
  );
};

export default SingleCourse;
